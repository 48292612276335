import React from "react"
import Box from "@material-ui/core/Box"

import Seo from "../components/Seo"
import LogoAndText from '../components/logoAndText'
import Layout from "../components/layout"

import { makeStyles } from "@material-ui/core/styles"
import { graphql, Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  logo: {
    width: "100%",
    height: 145,
    maxWidth: 340,
    transition: "opacity 150ms",
    "&:hover": {
      opacity: 0.75,
    },
    "&:hover:active": {
      opacity: 1,
    }
  }
}))

const IndexPage = ({ data, location }) => {
  const classes = useStyles()
  return (
    <Layout showLogo={false} location={location}>
      <Seo
        title={data.datoCmsHomePage.seo?.title}
        description={data.datoCmsHomePage.seo?.description}
        meta={data.datoCmsHomePage.tags}
      />
      <Box mt={-10} p={4}>
        <Link to="/portfolio/">
          <LogoAndText className={classes.logo} />
        </Link>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsHomePage {
      seoMetaTags {
        tags
      }
      seo {
        title
        description
      }
    }
  }
`

export default IndexPage
